<template>
  <div>
    <el-table v-loading="loading" :data="infoList" border style="width: 100%">
      <el-table-column prop="name" label="分子名称" width="180">
      </el-table-column>
      <el-table-column prop="molecular_formula" label="分子式" width="180">
      </el-table-column>
      <el-table-column prop="cas_number" label="CAS号码"></el-table-column>
      <el-table-column prop="pub_chem_cid" label="CID"></el-table-column>
      <el-table-column prop="audit_user_name" label="审核人"></el-table-column>
      <el-table-column prop="create_time" label="审核时间">
        <template slot-scope="scope">
          <span>{{ timestampToTime(scope.row.create_time) }}</span>
        </template></el-table-column
      >
      <el-table-column prop="status" label="状态" width="100"
        ><template slot-scope="scope">
          <el-tag
            :type="
              scope.row.status == 1
                ? 'info'
                : scope.row.status == 2
                ? 'success'
                : scope.row.status == 3
                ? 'warning'
                : 'danger'
            "
            >{{
              scope.row.status == 1
                ? "待审核"
                : scope.row.status == 2
                ? "已通过"
                : scope.row.status == 3
                ? "未通过"
                : "已下线"
            }}</el-tag
          >
        </template>
      </el-table-column>
      <el-table-column label="操作" width="135">
        <template slot-scope="scope">
          <el-button
            size="mini"
            type="primary"
            @click="handleShowDetail(scope.$index, scope.row)"
            >查看</el-button
          >
        </template>
      </el-table-column>
    </el-table>
    <el-pagination
      @size-change="handleSizeChange"
      @current-change="handleCurrentChange"
      :current-page="pageNum"
      :page-sizes="[5, 10, 15, 20]"
      :page-size="pageSize"
      layout="total, sizes, prev, pager, next, jumper"
      :total="total"
    >
    </el-pagination>
    <el-dialog v-if="dialogVisible" :visible.sync="dialogVisible" width="50rem">
      <el-descriptions title="编辑者信息" border :column="2">
        <el-descriptions-item
          ><template slot="label">
            <i class="el-icon-user"></i>
            用户名 </template
          >{{ basicInfo.name }}</el-descriptions-item
        >
        <el-descriptions-item
          ><template slot="label">
            <i class="el-icon-user"></i>
            身份
          </template>
          <el-tag
            size="small"
            :type="basicInfo.role == 1 ? 'warning' : 'info'"
            >{{ basicInfo.role == 1 ? "管理员" : "普通用户" }}</el-tag
          >
        </el-descriptions-item>
        <el-descriptions-item
          ><template slot="label">
            <i class="el-icon-mobile"></i>
            手机号 </template
          >{{ basicInfo.phone }}</el-descriptions-item
        >
        <el-descriptions-item
          ><template slot="label">
            <i class="el-icon-message"></i>
            电子邮箱 </template
          >{{ basicInfo.email }}</el-descriptions-item
        >
      </el-descriptions>
      <el-descriptions title="分子基本信息" border :column="2">
        <el-descriptions-item label="分子名称">{{
          row.name
        }}</el-descriptions-item>
        <el-descriptions-item label="分子式">{{
          row.molecular_formula
        }}</el-descriptions-item>
        <el-descriptions-item label="CAS号码">{{
          row.cas_number
        }}</el-descriptions-item>
        <el-descriptions-item label="CID">{{
          row.pub_chem_cid
        }}</el-descriptions-item>
      </el-descriptions>
      <el-descriptions title="审核信息" border :column="1">
        <el-descriptions-item label="所填分子性质">
        <div v-html="row.content"></div>
      </el-descriptions-item>
      <el-descriptions-item label="分子性质修改备注">
        {{ row.update_remark }}
      </el-descriptions-item>
      <el-descriptions-item label="审核意见">
        {{ row.remark }}
      </el-descriptions-item>
      </el-descriptions>
      <span slot="footer" class="dialog-footer">
        <el-button type="primary" @click="dialogVisible = false"
          >确 定</el-button
        >
      </span>
    </el-dialog>
  </div>
</template>
  
  <script>
export default {
  name: "PropertieRecordAlreadyAuditRecordSelect",
  data() {
    return {
      loading: false,
      dialogVisible: false,
      row: {},
      //编辑人基本信息
      basicInfo: {},
      //分页数据--start
      pageSize: 10,
      pageNum: 1,
      total: 0,
      //分页数据--end
      infoList: [],
    };
  },
  mounted() {
    this.getInfo();
  },
  methods: {
    getInfo() {
      this.loading = true;
      this.$store
        .dispatch("propertieRecordAuditRecord/selectAlreadyAudit", {
          pagesize: this.pageSize,
          pagenum: this.pageNum,
        })
        .then((res) => {
          var { data } = res;
          this.infoList = data.info_list;
          this.total = data.total;
          this.loading = false;
        });
    },
    //分页控件函数--start
    handleSizeChange(val) {
      this.pageSize = val;
      this.getInfo();
    },
    handleCurrentChange(val) {
      this.pageNum = val;
      this.getInfo();
    },
    //查看编辑人信息
    handleShowDetail(index, row) {
      this.row = row;
      this.$store
        .dispatch("user/selectBasicInfo", {
          id: row.edit_user_id,
        })
        .then((res) => {
          var { data } = res;
          this.basicInfo = data;
          this.dialogVisible = true;
        });
    },
    //分页控件函数--end
    //时间戳转换为时间
    timestampToTime(timestamp) {
      var date = new Date(parseInt(timestamp)); //时间戳为10位需*1000，时间戳为13位的话不需乘1000,需要将字符串转化为int
      var Y = date.getFullYear() + "-";
      var M =
        (date.getMonth() + 1 < 10
          ? "0" + (date.getMonth() + 1)
          : date.getMonth() + 1) + "-";
      var D =
        (date.getDate() < 10 ? "0" + date.getDate() : date.getDate()) + " ";
      var h =
        (date.getHours() < 10 ? "0" + date.getHours() : date.getHours()) + ":";
      var m =
        (date.getMinutes() < 10 ? "0" + date.getMinutes() : date.getMinutes()) +
        ":";
      var s =
        date.getSeconds() < 10 ? "0" + date.getSeconds() : date.getSeconds();
      return Y + M + D + h + m + s;
    },
  },
};
</script>
  
  <style scoped>
</style>