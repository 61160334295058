<template>
    <el-card class="info_card">
      <div slot="header">
        <span>已审核分子性质信息历史记录</span>
      </div>
      <PropertieRecordAlreadyAuditRecordSelect ref="PropertieRecordAlreadyAuditRecordSelect"/>
    </el-card>
  </template>
  
  <script>
  import PropertieRecordAlreadyAuditRecordSelect from "./PropertieRecordAlreadyAuditRecordSelect/index.vue"
  export default {
    components:{PropertieRecordAlreadyAuditRecordSelect},
    data() {
      return {
        isLoading: false,
      };
    },
    methods: {
      getInfo() {
        this.$refs.PropertieRecordAlreadyAuditRecordSelect.getInfo();
      },
    },
  };
  </script>
  
  <style scoped>
  </style>